/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~animate.css/animate.min.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Montse rrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Festive&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

::-webkit-scrollbar {
  display: none;
}

.toast {
  --min-height: 100px;
}


html,
body,
* {
  font-family: 'Inter', sans-serif;
}

::ng-deep.toast-container {
  flex-direction: column;
}

.sc__inner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sc__wrapper {
  display: block;
  width: 100%;
  height: 250px;
  max-width: 400px;
  margin: 0 auto;
  //border: px solid white;
}

.sc__container {
  position: relative;
  overflow: hidden;
  height: 250px;
  max-width: 400px;
}

.sc__container>img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.sc__container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.sc__infos {
  text-align: center;

}

.custom-loading {
  background-color: transparent;
  --background: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
}

ion-loading.custom-loading {
  .loading-wrapper {
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;

    .spinner-crescent circle {
      stroke-width: 8px;
    }
  }
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

ion-button {
  --border-radius: 4px;
  font-family: "Inter";
}

.toast-container {
  flex-direction: column;
}

.title-inner {
  padding: 5px;
  color: black;
  background-color: rgba(255, 255, 255, 0.432);
  backdrop-filter: blur(2px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: var(--ion-safe-area-top);
}

.swiper-pagination {
  background-color: #ffffff94;
  width: 120px !important;
  border-radius: 40px;
  margin-left: Calc(50% - 60px);
}

orwi-store-title {
  z-index: 100;
}

input {
  border: 0px;
  outline: none;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--ion-background-color);
  color: var(--ion-text-color);
  margin-bottom: 2px;
  margin-top: 2px;
}

textarea {
  border: 0px;
  background-color: var(--ion-background-color);
  color: var(--ion-text-color);
  outline: none;
  margin-bottom: 2px;
  margin-top: 2px;
}

.ticket-create-modal .modal-wrapper {
  height: 50%;
  position: fixed;
  bottom: 0;
  overflow-y: scroll;
}

.contract-modal::part(content) {
  --width: 100%;
  --height: 100%;
}

#payment-button.button-disabled {
  opacity: 0.1 !important;
}

#global-loading {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;

  &.active {
    visibility: visible;
  }
}

ion-back-button{
  --border-radius: 15px;
}

ion-back-button.light-bg{
  --background: rgba(255, 255, 255, 0.8);
}

ion-back-button.dark-bg{
  --background: rgba(0, 0, 0, 0.6);
}

ion-back-button::part(native){
  padding-left: 5px;
  padding-top: 1px;
}

ion-back-button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit; 
  border-radius: inherit; 
  opacity: inherit; 
  z-index: -1;
}